import React from "react";
import { graphql, PageProps } from "gatsby";
import styled from "styled-components";

import { Query } from "@graphql-types";
import SEO from "@shared/seo";
import { PageContext } from "@util/types";
import { Hero } from "@components";
import FooterSections from "@shared/footer/footerSections";
import { Container, GridContainer, PageWidth, Section } from "@util/standard";
import { BlocksContent, ContactForm } from "@global";
import { colorsRGB } from "@util/constants";

interface Props extends PageProps {
  data: Query;
  pageContext: PageContext;
}

const SectionDetails = styled(Section)`
  border-bottom: 1.5px solid ${colorsRGB.main(0.2)};
`;

const PageWidthStyled = styled(PageWidth)`
  max-width: 700px;
`;

export default function ContactTemplate({ data, pageContext }: Props) {
  if (!data.sanityContactPage) return null;

  const { pageMeta, blockContent, additionalInfo, contactFormTitle } = data.sanityContactPage;

  if (!pageMeta) return null;

  return (
    <>
      <SEO seoData={pageMeta.seo} slug={pageContext.pagePath} />
      <Hero data={pageMeta.pageHero} />
      <SectionDetails>
        <PageWidthStyled>
          <GridContainer repeat={2} gap="35px" width="100%">
            <BlocksContent data={blockContent} />
            <BlocksContent data={additionalInfo} />
          </GridContainer>
        </PageWidthStyled>
      </SectionDetails>
      <Section>
        <PageWidthStyled>
          <Container flexDirection="column">
            <BlocksContent data={contactFormTitle} margin="0 0 35px" />
            <ContactForm />
          </Container>
        </PageWidthStyled>
      </Section>
      <FooterSections pageFooter={pageMeta.pageFooter} />
    </>
  );
}

export const query = graphql`
  query ContactQuery {
    sanityContactPage {
      pageMeta {
        ...sanityPageMeta
      }
      blockContent {
        ...sanityBlockContent
      }
      additionalInfo {
        ...sanityBlockContent
      }
      contactFormTitle {
        ...sanityBlockContent
      }
    }
  }
`;
